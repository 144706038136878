import {
    Observable, throwError
} from "rxjs";
import {
    catchError, map
} from "rxjs/operators";

import {
    Injectable, Inject,
    ErrorHandler
} from "@angular/core";
import {
    HttpClient
} from "@angular/common/http";
import {
    CommonErrorHandler
} from "@shopliftr/common-ng";
import { Results } from "@shopliftr/common-js/shared";
import { ModelUtil } from "@shopliftr/common-js/data";
import { Keyword } from "@shopliftr/common-js/admin";

/**
 * Use HttpClient to handle calls to InternalApi v1 endpoints related to Keyword
 */
@Injectable({ providedIn: "root" })
export class KeywordService {

    private readonly _keywordUrl: string;

    constructor(
        private readonly _httpClient: HttpClient,
        @Inject("AppConfig") private readonly _appConfig: any,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler
    ) {

        this._keywordUrl = `${this._appConfig.apiUrl}/v1/keyword`;
    }


    /**
     *
     */
    getKeywords(query?: string, from?: number, size?: number): Observable<Results<Keyword>> {

        const body: any = {};

        if (query) {
            body.query = query;
        }
        if (from) {
            body.from = from;
        }
        if (size) {
            body.size = size;
        }
        const url = `${this._keywordUrl}/search`;
        return this._httpClient.post(url, body).pipe(
            map((response: unknown): Results<Keyword> => {

                const results: Results<Keyword> = Results.deserialize(response, ModelUtil.CamelCase, Keyword);
                return results;
            }),
            catchError((error) => {

                return throwError(() => this._errorHandler.handleServiceError(error));
            })
        );
    }


    deleteKeyword(keyword: Keyword): Observable<boolean> {

        const url = `${this._keywordUrl}/${keyword.id}`;
        return this._httpClient.delete(url, { body: { esVersion: keyword.esVersion } }).pipe(
            map((): boolean => {

                return true;
            }),
            catchError((error) => {

                return throwError(() => this._errorHandler.handleServiceError(error));
            })
        );
    }


    createKeyword(keyword: Keyword): Observable<Keyword> {

        const body = keyword.serialize();
        return this._httpClient.post(this._keywordUrl, body).pipe(
            map((): Keyword => {

                return Keyword.deserialize(body);
            }),
            catchError((error) => {

                return throwError(() => this._errorHandler.handleServiceError(error));
            })
        );
    }


    updateKeyword(keyword: Keyword): Observable<Keyword> {

        const url = `${this._keywordUrl}/${keyword.id}`;

        return this._httpClient.put(url, keyword.serialize()).pipe(
            map((response: unknown): Keyword => {

                return Keyword.deserialize(response);
            }),
            catchError((error) => {

                return throwError(() => this._errorHandler.handleServiceError(error));
            })
        );
    }
}
